<template>
  <div>
    <asom-alert v-if="!isOnline" title="Action not allowed" variant="error">
      <p class="text-sm">
        When offline, changing your station will result in the loss of any unaved data.
        Please go online to continue to proceed
      </p>
    </asom-alert>
    <div class="space-y-4">
      <asom-alert title="Before to continue">
        <div class="space-y-1 text-sm">
          <p>
            This action will change the current station and reload all data for
            the following modules. Please ensure you do not have any pending
            activity within the current station.
          </p>
          <ul class="list-disc pl-5 ">
            <li>Cash Management</li>
            <li>Inventory Management</li>
            <li>Fault Management</li>
            <li>Station Occurrence</li>
            <li>
              Shift Handover (excepting creating shift handover and takeover)
            </li>
          </ul>
          <p>
            After changing station, any changes made in the above modules will
            be recorded under the new station loaded.
          </p>
        </div>
      </asom-alert>
      <asom-card>
        <asom-alert
          class="mb-4"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <asom-grid :sm="1" :md="2">
          <asom-form-field label="Line" required :disabled="!canSelectLine">
            <asom-input-select
              :disabled="!canSelectLine"
              v-model="lineId"
              :options="lineOptions"
              :state="inputStates('lineId')"
              :objectModelValue="false"
            />
          </asom-form-field>
          <asom-form-field label="Station" required>
            <asom-input-select
              :objectModelValue="false"
              v-model="stationId"
              :options="stationOptions"
              :state="inputStates('stationId')"
            />
          </asom-form-field>
          <asom-form-field
            label="Current Shift's Roster"
            v-if="currentStationId != rosterStationId"
          >
            <div class="flex space-x-4">
              <p class="font-semibold mt-2">{{ rosterStationName }}</p>
              <asom-button :text="'Reset'" @click="resetStation" />
            </div>
          </asom-form-field>
        </asom-grid>
        <template #footer>
          <asom-button text="Cancel" variant="secondary" @click="goBack" />
          <asom-button
            text="Submit"
            @click="submit"
            :disabled="isLoading"
            :is-loading="isLoading"
          />
        </template>
      </asom-card>
      <asom-modal title="Loading Information" :dismissible="false">
        <div class="text-center">
          <p>Preparing data for new selected station...</p>
        </div>
      </asom-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import AsomButton from "../components/button/AsomButton.vue";

export default {
  components: { AsomButton },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    let stationId = null;
    let lineId = null;
    const hasSelectedStation = this.$store.getters[
      "selectedStation/isSelected"
    ];
    if (hasSelectedStation) {
      stationId = this.$store.getters["selectedStation/id"];
      lineId = this.$store.getters["selectedStation/lineId"];
    }
    return {
      error: null,
      isLoading: false,
      stationId: stationId,
      lineId: lineId,
    };
  },
  validations() {
    return {
      stationId: {
        required,
      },
      lineId: {
        required,
      },
    };
  },
  watch: {
    lineId: function(newValue) {
      if (newValue) {
        this.stationId = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      rosterStationId: "currentRoster/currentStationId",
      rosterStationName: "currentRoster/currentStationName",
      rosterLineId: "currentRoster/currentLineId",
      currentStationId: "selectedStation/id",
      stationOptions: "smrtResource/stationOptions",
      isOnline: "apiStatus/isAllApiOnline",
    }),
    selectedStation() {
      return this.stationOptions.find(({ value }) => value == this.stationId);
    },
    selectedLine() {
      return this.lineOptions.find(({ value }) => value == this.lineId);
    },
    canSelectLine() {
      return this.$store.getters["auth/canSelectLine"];
    },
    stationOptions() {
      let lineId = null;
      if (this.canSelectLine) {
        lineId = this.lineId;
      } else {
        lineId = this.$store.getters["auth/userLineId"];
      }
      return this.$store.getters["smrtResource/stationOptionsByLineId"](lineId);
    },
    redirectLink() {
      if (this.$route.query && this.$route.query.redirect) {
        return this.$route.query.redirect;
      }
      return "#/cash-mgnt";
    },
  },
  methods: {
    resetStation() {
      this.stationId = this.rosterStationId;
      this.lineId = this.rosterLineId;
      this.submit();
    },
    async submit() {
      
      this.isLoading = false;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid || !this.selectedStation) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
        return;
      } else {
        this.isLoading = true;
        await this.$store.dispatch("selectedStation/changeStation", {
          stationId: this.selectedStation.value,
          stationName: this.selectedStation.name,
          lineId: this.selectedLine.value,
        });
        this.$store.dispatch("auth/setLineName", this.selectedLine.label);
        this.$store.dispatch("auth/setLineId", this.selectedLine.value);
        await this.$store.dispatch("cashManagement/loadInitialData");
        await this.$store.dispatch("inventoryManagement/loadInitialData");
        this.isLoading = false;
        this.goBack();
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
